import React, { useEffect } from 'react'
import { Grid, Typography, Button } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { userSlice } from '../../reducers'
import { useDispatch } from 'react-redux'
import { AppToolbar, MailtoLink } from '../../components'
import { useStyles } from './style'

const NotFound = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const hj = window.hj
  const dataLayer = window.dataLayer || []
  const handleHomeClick = () => {
    dispatch(userSlice.actions.setActiveMenuSection('home'))
    dispatch(userSlice.actions.setMenuLevel(0))
    navigate('/')
  }
  const ScrollToTopOnMount = () => {
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    return null
  }

  useEffect(() => {
    if (!hj) return
    hj('stateChange', '/not-found')
    dataLayer.push({
      event: 'stateChange',
      attributes: {
        path: '/not-found'
      }
    })
  })

  return (
    <div>
      <ScrollToTopOnMount />
      <AppToolbar />
      <Grid
        className={classes.root}
        container
      >
        <Grid item xs={6} className={classes.leftBox}>
          <Typography variant='h1'>
            Hmm...
          </Typography>
          <Typography style={{ fontSize: 20 }}>
            Something’s gone wrong there. But not to worry—you can just head back to where you were.
          </Typography>
          <div className={classes.buttons}>
            <Button style={{ marginRight: '16px' }} onClick={handleHomeClick} variant='contained' color='secondary'>Home</Button>
            <Button variant='outlined' color='secondary'>
              <MailtoLink email='lpbenchmark@mindtools.com'>Get in touch</MailtoLink>
            </Button>
          </div>
        </Grid>
        <Grid item xs={6} className={classes.rightBox}>
          <img src='/img/404_Page.png' height='400px' alt='not found' />
        </Grid>
      </Grid>
    </div>
  )
}

export default NotFound
