import React, { useCallback, useEffect } from 'react'
import { getStageByScore } from '../../helpers/utils'
import {
  AppToolbar,
  NavigationDrawer,
  DashboardSummary,
  DashboardScore,
  DashboardScoreSkeleton,
  DashboardAreas,
  DashboardAreasSkeleton,
  DashboardMaturity,
  DashboardMaturitySkeleton,
  DashboardTrends,
  DashboardTrendsSkeleton,
  PageAlert,
  GraphsForPDF
} from '../../components'
import { parseTrendsResponses } from '../dashboard/helpers'
import { Button, IconButton } from '@material-ui/core'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useSelector, useDispatch } from 'react-redux'
import { useEvent, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { dashboardSlice, surveySlice, teamSlice, userSlice } from '../../reducers'
import { useStyles } from './style'
import { datadogRum } from '@datadog/browser-rum'

const TeamDashboardPage = () => {
  const classes = useStyles()
  const dispatcher = useDispatch()
  const [showAlert, setShowAlert] = React.useState(false)
  const currentPage = useSelector(dashboardSlice.selectors.selectPage)
  const innerPage = useSelector(dashboardSlice.selectors.selectInnerPage)

  const userData = useSelector(userSlice.selectors.selectUserData)
  const teamChartDataError = useSelector(teamSlice.selectors.selectTeamChartDataError)
  const teamChartData = useSelector(teamSlice.selectors.selectTeamChartData)

  const [getTeamScoresChartData] = useEvent([teamSlice.eventBus.getTeamScoresChartData])

  const hj = window.hj
  const dataLayer = window.dataLayer || []

  const isXSScreen = useSelector(surveySlice.selectors.selectIsXSScreen)
  const isMDScreen = useSelector(surveySlice.selectors.selectIsMDScreen)
  const isLGScreen = useSelector(surveySlice.selectors.selectIsLGScreen)
  const isXLScreen = isLGScreen
  const isPDFDownloading = useSelector(dashboardSlice.selectors.selectPDFDownloading)

  const [showNavBar, setShowNavBar] = React.useState(isLGScreen)
  const [showFloatBtn, setShowFloatBtn] = React.useState(false)
  const [hideNav, setHideNav] = React.useState(false)
  const [ovdHideNav, setOvdHideNav] = React.useState(false)

  const openNavBar = () => {
    setShowNavBar(true)
  }
  const closeNavBar = () => {
    setShowNavBar(false)
  }

  const handleNavDrawerHover = () => {
    if (!hideNav) {
      setShowFloatBtn(true)
    } else {
      setOvdHideNav(true)
    }
  }

  const handleContentHover = () => {
    setShowFloatBtn(false)
    setOvdHideNav(false)
  }

  const toggleHideNav = () => {
    setHideNav(!hideNav)
    setShowFloatBtn(true)
  }

  const handlePageView = () => {
    if (!hj || !datadogRum) return
    datadogRum.startView(`team-dashboard/${currentPage !== 'team-dashboard' ? currentPage : ''}${innerPage ? `/${innerPage}` : ''}`)
    hj('stateChange', `team-dashboard/${currentPage !== 'team-dashboard' ? currentPage : ''}${innerPage ? `/${innerPage}` : ''}`)
    dataLayer.push({
      event: 'stateChange',
      attributes: {
        path: `team-dashboard/${currentPage !== 'team-dashboard' ? currentPage : ''}${innerPage ? `/${innerPage}` : ''}`
      }
    })
  }

  useEffect(() => {
    if (isLGScreen) setShowNavBar(true)
    if (isMDScreen) setShowNavBar(false)
  }, [isLGScreen, isMDScreen])

  useEffect(() => {
    if (teamChartDataError) {
      datadogRum.addError(new Error('backend error: could not aggregate chart data'))
      setShowAlert(true)
    } else {
      setShowAlert(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamChartDataError])

  useEventsOnViewLoad(() => {
    if (teamChartData === undefined) {
      getTeamScoresChartData.trigger()
    }
  }, [getTeamScoresChartData])

  useEffect(() => {
    handlePageView()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, innerPage])

  const setCurrentPage = useCallback((name) => {
    dispatcher(dashboardSlice.actions.setPage(name))
    dispatcher(dashboardSlice.actions.setInnerPage(''))
    if (name === 'areas') {
      dispatcher(dashboardSlice.actions.setInnerPage('Align'))
    }
    if (name === 'trends') {
      dispatcher(dashboardSlice.actions.setInnerPage('Technology'))
    }
  }, [dispatcher])

  const setInnerPage = useCallback((name) => {
    dispatcher(dashboardSlice.actions.setInnerPage(name))
  }, [dispatcher])

  const userScore = teamChartData ? Number.parseInt(teamChartData.results.score) : 0
  const userStage = teamChartData ? getStageByScore(userScore) : 1
  const scoresChartData = teamChartData ? teamChartData.scoresChartData : []
  const resultsSeason = scoresChartData.length ? scoresChartData[0].values[0].date : ''
  const componentsChartData = teamChartData ? teamChartData.componentsChartData : {}
  const behavioursData = teamChartData ? teamChartData.behaviours : {}
  const userIndustry = teamChartData ? teamChartData.profile.industry : 'IT and Telecoms'
  const surveyResponses = teamChartData ? teamChartData.responses : []
  let teamMembers = teamChartData ? teamChartData.members : []
  // remove members that haven't finished the review yet
  teamMembers = Array.isArray(teamMembers) ? teamMembers.filter(member => member.scores !== null) : []

  const { skillsResponses, techResponses, barrierResponses } = parseTrendsResponses(surveyResponses)

  return (
    <div className={classes.root}>
      <AppToolbar
        isMobileScreen={isMDScreen || isXSScreen}
        navBarOpen={showNavBar}
        openNavBar={openNavBar}
        closeNavBar={closeNavBar}
      />
      <NavigationDrawer
        activeDashboardLink={currentPage}
        activeInnerLink={innerPage}
        setActiveDashboardLink={setCurrentPage}
        setActiveInnerLink={setInnerPage}
        isLoading={getTeamScoresChartData.isWorking}
        isLGScreen={isLGScreen}
        showNavBar={showNavBar}
        closeNavBar={closeNavBar}
        hideNav={hideNav}
        ovdHideNav={ovdHideNav}
        onMouseOver={handleNavDrawerHover}
      />
      {((isLGScreen && showFloatBtn) || (isLGScreen && hideNav)) && (
        <IconButton
          aria-label='toggle navigation menu'
          className={isLGScreen && ((!hideNav && !ovdHideNav) || (hideNav && ovdHideNav)) ? `${classes.collapseBtn} ${isXLScreen && classes.collapseBtnXL}` : `${classes.collapseBtnHide} ${isXLScreen && classes.collapseBtnHideXL}`}
          onClick={toggleHideNav}
        >
          {
            !hideNav
              ? (
                <KeyboardArrowLeft
                  className={classes.collapseIcon}
                />
                )
              : (
                <KeyboardArrowRight
                  className={classes.collapseIcon}
                />
                )
                  }
        </IconButton>
      )}
      <div
        className={isLGScreen && ((!hideNav && !ovdHideNav) || (hideNav && ovdHideNav)) ? `${classes.main} ${currentPage === 'dashboard' && classes.summarySpacing} ${isXLScreen && classes.mainXL}` : `${classes.mainMD} ${classes.mainXLCollapsed}`}
        onMouseOver={handleContentHover}
        id='main'
      >
        {currentPage !== 'dashboard' && (
          <div>
            <Button
              className={classes.button}
              startIcon={<ArrowBackIcon />}
              onClick={() => setCurrentPage('dashboard')}
              data-test='btn-backToSummary'
            >
              Back to summary
            </Button>
          </div>
        )}
        {currentPage === 'dashboard' && (
          <DashboardSummary
            setDashboardPage={setCurrentPage}
            setInnerDashboardPage={setInnerPage}
            score={{ score: scoresChartData, chartDataError: teamChartDataError }}
            stage={userStage}
            isLoading={getTeamScoresChartData.isWorking}
            industry={userIndustry}
            isTeam
          />
        )}
        {currentPage === 'score' &&
          (teamChartData
            ? <DashboardScore score={scoresChartData} profile={teamChartData.profile} members={teamMembers} isTeam userLoggedIn={userData} />
            : <DashboardScoreSkeleton />)}
        {currentPage === 'areas' && (teamChartData
          ? <DashboardAreas
              innerPage={innerPage}
              setTab={setInnerPage}
              areasScores={componentsChartData}
              profile={teamChartData.profile}
              behaviours={behavioursData}
              isTeam
              members={teamMembers}
              userLoggedIn={userData}
            />
          : <DashboardAreasSkeleton />)}
        {currentPage === 'stage' && (teamChartData
          ? <DashboardMaturity stage={userStage} scores={scoresChartData} profile={teamChartData.profile} resultsSeason={resultsSeason} members={teamMembers} isTeam />
          : <DashboardMaturitySkeleton />
        )}
        {currentPage === 'trends' && (teamChartData
          ? <DashboardTrends
              innerPage={innerPage}
              setTab={setInnerPage}
              season={resultsSeason}
              tech={techResponses}
              challenges={barrierResponses}
              skills={skillsResponses}
              isTeam
            />
          : <DashboardTrendsSkeleton />
        )}
      </div>
      {showAlert && (
        <PageAlert text='Issue fetching averages. If error persists, try contacting us!' error />
      )}
      {isPDFDownloading && (
        <GraphsForPDF
          componentsChartData={componentsChartData}
          scoresChartData={scoresChartData}
          stage={userStage}
          profile={teamChartData.profile}
          tech={techResponses}
          challenges={barrierResponses}
          skills={skillsResponses}
        />
      )}
    </div>
  )
}

export default TeamDashboardPage
