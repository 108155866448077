import React from 'react'
import { Page, View, Text, Link, Image } from '@react-pdf/renderer'
import Logo from '../../img/LPB-logo-white.png'
import styles from './style'
import globalStyles from '../../style'

const FinalPage = () => (
  <Page size='A4' orientation='landscape' wrap={false} style={{ backgroundColor: '#006fb7' }}>
    <View style={[globalStyles.pageView, { display: 'flex', flexDirection: 'row' }]}>
      <View style={styles.textContainer}>
        <Text style={[globalStyles.subheading, { color: 'white' }]}>Become part of our learning community</Text>
        <Text style={[globalStyles.body, { color: 'white' }]}>Join our Learning Innovation Group, a dynamic, thought-provoking and practical community of practice for learning leaders. For over 10 years our group has been running workshops, seminars and podcasts—all powered by our independent research.</Text>
        <Text style={[globalStyles.body, { color: 'white' }]}>Visit <Link style={[globalStyles.body, { color: 'white' }]}>mindtoolsbusiness.com/learningcommunity/learning-innovation-group</Link></Text>

        <Text style={[globalStyles.subheading, { color: 'white' }]}>Want further insights?</Text>
        <Text style={[globalStyles.body, { color: 'white' }]}>We provide L&D with the support they need to bring together all key stakeholders in the learning journey, ensuring the learning function works effectively to embed a high-performing learning culture across the business. By applying the breadth and depth of our industry analytics and our robust, evidence-based approach, we will connect your learning decisions and outcomes to real business impact. If you think your organisation would benefit from our analyst service, email <Link style={[globalStyles.body, { color: 'white' }]}>insights@mindtools.com</Link></Text>

        <View style={{ padding: 5, border: '1px solid white', marginTop: 20 }}>
          <Text style={[globalStyles.subheading, { color: 'white', marginBottom: 2, marginTop: 2 }]}>Was this PDF useful?</Text>
          <Text style={[globalStyles.body, { color: 'white' }]}>We’d love to get your thoughts. What was good? Was there any information missing? Please let us know. Email us at <Link style={[globalStyles.body, { color: 'white' }]}>lpbenchmark@mindtools.com</Link></Text>
        </View>
      </View>
      <View style={styles.logoContainer}>
        <Image style={styles.logo} src={Logo} />
      </View>

    </View>
  </Page>
)

export default FinalPage
