import React, { useEffect } from 'react'
import { Grid, Typography, Button, CircularProgress } from '@material-ui/core'
import { useLocation, useNavigate } from 'react-router-dom'
import { AppToolbar } from '../../components'
import { useStyles } from './style'
import { useSession } from '@emerald-works/react-auth'
import { useEvent } from '@emerald-works/react-event-bus-client'
import { userSlice } from '../../reducers'
import { useSelector, useDispatch } from 'react-redux'

const Payment = () => {
  const [validateSubscription] = useEvent([
    userSlice.eventBus.validateSubscription
  ])
  const isSubscriptionValidated = useSelector(
    userSlice.selectors.selectSubscriptionValidated
  )
  const dispatch = useDispatch()
  const subscriptionError = useSelector(
    userSlice.selectors.selectSubscriptionError
  )
  const classes = useStyles()
  const navigate = useNavigate()
  const hj = window.hj
  const dataLayer = window.dataLayer || []
  const session = useSession()
  const search = new URLSearchParams(useLocation().search)
  const success = search.get('success') || ''
  const plan = search.get('plan') || ''
  const ScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
    return null
  }

  const [paymentSuccess, setPaymentSuccess] = React.useState(false)
  const [redirecting, setRedirecting] = React.useState(false)
  const { user } = session

  useEffect(() => {
    if (success === 'true') {
      validateSubscription.trigger()
      setPaymentSuccess(true)
    }
  }, [success, validateSubscription])

  const buildUrlParams = (name, email) => {
    const [firstName, lastName] = name.split(' ')
    if (lastName) {
      return `first_name=${firstName}&last_name=${lastName}&email=${email}`
    }
    return `first_name=${firstName}&email=${email}`
  }

  const handleRedirect = React.useCallback(
    () => {
      setRedirecting(false)
      const { attributes } = user
      const { name, email } = attributes
      const urlParams = buildUrlParams(name, email)
      window.location.href = `https://mindtools.eu.recurly.com/subscribe/lpb-t1?${urlParams}`
    }, [user])

  useEffect(() => {
    if (success === 'true' || plan) return
    setRedirecting(true)
    const timer = setTimeout(() => {
      handleRedirect()
    }, 5000)
    return () => {
      clearTimeout(timer)
    }
  }, [plan, success, user, handleRedirect])

  useEffect(() => {
    if (!hj) return
    hj('stateChange', '/payment')
    dataLayer.push({
      event: 'stateChange',
      attributes: {
        path: '/payment'
      }
    })
  })

  const handleTryAgain = () => {
    dispatch(userSlice.actions.clearSubscriptionError())
    validateSubscription.trigger()
  }

  const handleContact = () => {
    navigate('/contact')
  }

  return (
    <div>
      <AppToolbar />
      <Grid className={classes.root} container spacing={0}>
        <ScrollToTop />
        {!paymentSuccess
          ? (
            <>
              <Grid item xs={8} className={classes.leftBox}>
                <Typography variant='h1'>Pay for your premium account</Typography>
                <Typography variant='body1'>
                  Thank you for your interest in the Learning Peformance
                  Benchmark! You are being redirected to our payment portal.
                </Typography>
                <Typography variant='body1'>
                  If you are not redirected within 5 seconds, please click the
                  button below.
                </Typography>

                <div className={classes.buttons}>
                  <Button
                    variant='contained'
                    className={classes.primaryButton}
                    style={{ marginRight: '16px' }}
                    onClick={handleRedirect}
                    endIcon={
                      redirecting && (
                        <CircularProgress
                          size={14}
                          className={classes.redirecting}
                        />
                      )
                    }
                  >
                    Visit payment portal
                  </Button>
                  <Button
                    onClick={() => navigate('/')}
                    variant='outlined'
                    color='secondary'
                  >
                    Home
                  </Button>
                </div>
              </Grid>
              <Grid item xs={4} className={classes.rightBox}>
                <img src='/img/verify-email.png' height='400px' alt='' />
              </Grid>
            </>
            )
          : isSubscriptionValidated
            ? (
              <>
                <Grid item xs={8} className={classes.leftBox}>
                  <Typography variant='h1'>Success!</Typography>
                  <Typography variant='body1'>
                    Congratulations! Your payment was successful
                  </Typography>
                  <Typography variant='body1'>
                    Return to the home page to start using the Learning Peformance
                    Benchmark.
                  </Typography>
                  <Grid item xs={12}>
                    <div className={classes.buttons}>
                      <Button
                        style={{ marginRight: '16px' }}
                        onClick={() => navigate('/')}
                        variant='outlined'
                        color='secondary'
                      >
                        Home
                      </Button>
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={4} className={classes.rightBox}>
                  <img src='/img/well-done.png' height='400px' alt='' />
                </Grid>
              </>
              )
            : subscriptionError
              ? (
                <>
                  <Grid item xs={6} className={classes.leftBox}>
                    <Typography variant='h1'>Subscription error</Typography>
                    <Typography variant='body1' style={{ marginBottom: 20 }}>
                      We were unable to validate your subscription. Please try again
                      and contact our support team if the problem persists.
                    </Typography>
                    <Grid item xs={12}>
                      <Button
                        variant='contained'
                        className={classes.primaryButton}
                        style={{ marginRight: '16px' }}
                        onClick={handleTryAgain}
                      >
                        Try again
                      </Button>
                      <Button
                        variant='outlined'
                        color='secondary'
                        onClick={handleContact}
                      >
                        Contact support
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} className={classes.rightBox}>
                    <img src='/img/404_Page.png' height='400px' alt='error' />
                  </Grid>
                </>
                )
              : (
                <>
                  <Grid item xs={12} className={classes.center}>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <Typography variant='h1'>
                        Validating your subscription
                      </Typography>
                      <CircularProgress style={{ marginLeft: 20 }} />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className={classes.center}>
                    <img src='/img/calculating.png' height='400px' alt='' />
                  </Grid>
                </>
                )}
      </Grid>
    </div>
  )
}

export default Payment
